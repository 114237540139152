export const TYPE = {
  PRODUCT: 'product',
  STOCK: 'stock',
  BACKGROUND: 'background',
  FOLDER: 'folder',
  FOLDER_CONTENT: 'folder-content',
  WALMART_ASSETS: 'walmart-assets'
}

export const MODE = {
  VIEW: 'view',
  EDIT: 'edit',
}

export const SOURCE = {
  PRODUCT: '/file/file_list?fileBrowser=true&searchKey=',
  STOCK: '/file/stock_list?searchKey=',
  BACKGROUND: '/file/background_list?searchKey=',
  FOLDER: '/file/folder_list?searchKey=',
  FOLDER_CONTENT: '/file/folder_content?searchKey=',
  SHARED_FOLDER: '/file/share/files/share_folder_list?searchKey=',
  SHARED_FOLDER_CONTENT: '/file/share/files/share_folder_content?searchKey=',
  FOLDER_CONTENT_RESOLVE_POINT: '/file/folder_content?',
  WALMART_ASSETS: '/walmart-assets/asset-files?searchKey='
}

export const MIME_TYPE = {
  IMAGE: 'image',
  ANY_IMAGE: 'image.*',
  ZIP: 'application/zip',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  PSD: 'image/vnd.adobe.photoshop',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
}

export const FILE_TYPE = {
  IMAGE: 'image',
  DOC: 'doc',
  XLS: 'xls',
  PDF: 'pdf',
  PSD: 'psd',
  ZIP: 'zip',
  TIFF: 'tiff',
  TIF: 'tif',
  SVG: 'svg',
  MP4: 'mp4',
  MP3: 'mp3',
  WAV: 'wav',
  M4A: 'm4a',
  AVI: 'avi',
  MOV: 'mov',
  WEBM: 'webm',
  GIF: 'gif',
  PNG: 'png',
  JPG: 'jpg',
  PPT: 'ppt',
  PPTX: 'pptx',
  JPEG: 'jpeg',
  WEBP: 'webp',
  FOLDER: 'folder',
  LINK: 'link',
  HTML: 'html',
  AI: 'ai',
  EPS: 'eps',
  BMP: 'bmp',
  MPG: 'mpg',
  WMV: 'wmv',
  FLV: 'flv',
  PSB: 'psb',
  JPG_FROM_PSD: 'jpg_from_psd',
  LAYOUT: 'layout'
}

export const PREVIEW_FILE_TYPES = {
	[FILE_TYPE.IMAGE]: 'image',
	[FILE_TYPE.GIF]: 'image',
	[FILE_TYPE.PNG]: 'image',
	[FILE_TYPE.JPG]: 'image',
	[FILE_TYPE.JPEG]: 'image',
	[FILE_TYPE.WEBP]: 'image',
	[FILE_TYPE.MP4]: 'video',
	[FILE_TYPE.WEBM]: 'video',
	[FILE_TYPE.MP3]: 'audio',
	[FILE_TYPE.WAV]: 'audio',
	[FILE_TYPE.MOV]: 'video',
}
